<template>
  <a-modal
      v-drag-modal
      :visible="visible"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      :title="title"
      :width="800"
      @ok="handleOk"
      @cancel="visible = false"
  >
    <a-form-model ref="ruleForm" :model="form" :rules="rules" v-if="visible">
      <a-form-model-item
          label="分数范围"
          prop="fromScore" required
          v-bind=" formItemLayout">
        <div style="display: flex;align-items: center">
          <a-input-number v-model="form.fromScore" v-decorator="['fromScore']" placeholder="开始分值"/>
          <span style="padding:0 10px;"> ~</span>
          <a-input-number v-model="form.toScore" v-decorator="['toScore']" placeholder="结束分值"/>
        </div>
      </a-form-model-item>
      <a-form-model-item label="结果说明" v-bind="formItemLayout">
        <a-textarea v-model="form.desc" :rows="4" allClear/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
export default {
  name: "AddScoreScope",
  data() {
    return {
      action: 'edit',
      formItemLayout: {
        labelCol: {span: 6},
        wrapperCol: {span: 17},
      },
      title: "",
      visible: false,
      confirmLoading: false,
      record: {},
      form: {},
      rules: {
        fromScore: [
          {
            required: true,
            message: "开始分值不能为空",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              let fromScore = this.form.fromScore
              let toScore = this.form.toScore
              if (fromScore && toScore && fromScore > toScore) {
                callback(new Error('开始分值不能大于结束分值'))
              } else {
                callback()
              }
            }
          }
        ],
        toScore: [
          {
            required: true,
            message: "结束分值不能为空",
            trigger: "blur",
          },
        ],
      },
      editMode: false,
    };
  },
  methods: {
    async showModal(record, action) {
      this.visible = true;
      this.action = action || 'edit';
      this.record = record || {}
      if (this.action == 'edit') {
        this.form = {
          ...record,
        };
        this.title = "修改结果区间：" + record.score;
      } else {
        this.form = {
          ...(record || {}),
        };
        this.title = "新增结果区间";
      }
    }
    ,
    handleOk() {
      this.$refs.ruleForm.validate((valid, values) => {
        if (valid) {
          this.confirmLoading = true;
          this.$postJson("evaluation/saveScore", this.form).then((res) => {
            this.confirmLoading = false;
            this.visible = false;
            this.$message.success("保存成功");
            this.$emit("success", res.data);
          }).catch(e => {
            this.confirmLoading = false;
          });
        } else {
          this.confirmLoading = false;
        }
      });
    }
  }
}
</script>
<style lang="less" scoped>
.ant-input-number {
  width: 100% !important;
}

.tip1 {
  color: #FF9900;
  margin: 0px 80px 20px;
}

</style>
